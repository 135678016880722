.Projects {
  overflow: hidden;
  margin: 20px auto;
}

.Card {
  margin: 60px 20px 30px 20px;
}

.cardImage {
  height: 50%;
}