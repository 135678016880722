.Footer {
  overflow: hidden;
  float: inline-start;
  background:hsla(199, 54%, 48%, 0.75);
  padding: 10px;
}

.Footer p {
  color: white;
}

.Footer a {
  color: white;
}