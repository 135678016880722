.Logo {
  width: 70%;
  margin: 50px auto;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.Title {
  font-size: xx-large;
  font: bold;
}

.Nav {
  margin-top: 30%;
}

.Contact {
  position: absolute;
  bottom: 5%;
  width: 100%;
}

.Contact svg {
  margin: 0px 12px auto;
  fill: white;
  width: 30px;
  height: 30px;
}