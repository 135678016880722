.Timeline {
  overflow: hidden;
  padding-bottom: 80px;
}

.Timeline-Root::before {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px #fff, 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline-element-date {
    text-align: left;
  }
}
