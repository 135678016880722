html, body, #root {
  height: 100%;
}

.App {
  text-align: center;
  margin: auto;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  background-image: url(./images/me_in_budapest.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.App h1 {
  margin-top: 80px;
  margin-bottom: 40px;
}

.App h3 {
  margin-bottom: 20px;
}

.Navigation {
  width: 25%;
  max-width: 350px;
  height: 100%;
  float: left;
  background:hsla(199, 54%, 48%, 0.75);
  text-align: center;
  z-index: 1;
  position: fixed;
  color: white;
}

.Navigation a {
  color: white;
}

.Main {
  background: #f8f9fb;
}

.Spacer {
  float: left;
  height: 2000px;
  width: 25%;
  max-width: 350px;
}

.About {
  height: 100%;
  width: 100%;
  background-image: url(./images/me_in_budapest.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}