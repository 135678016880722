.Skills {
  overflow: hidden;
  margin-top: 80px;
}

.Circles {
  display: list-item;
  padding: 20px 50px 50px 50px;
}

.Circle {
  margin: 2% 5% auto;
}

.CircularProgressbar.Circle {
  margin: 50px 50px 50px 50px auto;
  height: 150px;
  width: 150px;
}