.Page {
  height: 100%;
  width: 100%;
}

.Welcome {
  display: inline-block;
  position: relative;
  color: white;
  top:7%;
  right: 0%;
  font-size: x-large;
}

.Title {
  font-size: 55px;
  font-weight: bold;
}